import * as React from 'react';

function SvgCalendarDayFill(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M4 .5a.5.5 0 00-1 0V1H2a2 2 0 00-2 2v1h16V3a2 2 0 00-2-2h-1V.5a.5.5 0 00-1 0V1H4V.5zM16 14a2 2 0 01-2 2H2a2 2 0 01-2-2V5h16v9zm-4.785-6.145a.428.428 0 100-.855.426.426 0 00-.43.43c0 .238.192.425.43.425zm.336.563h-.672v4.105h.672V8.418zm-6.867 4.105v-2.3h2.261v-.61H4.684V7.801h2.464v-.61H4v5.332h.684zm3.296 0h.676V9.98c0-.554.227-1.007.953-1.007.125 0 .258.004.329.015v-.613a1.806 1.806 0 00-.254-.02c-.582 0-.891.32-1.012.567h-.02v-.504H7.98v4.105z' />
		</svg>
	);
}

export default SvgCalendarDayFill;
