import * as React from 'react';

function SvgCalendarPlusFill(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M4 .5a.5.5 0 00-1 0V1H2a2 2 0 00-2 2v1h16V3a2 2 0 00-2-2h-1V.5a.5.5 0 00-1 0V1H4V.5zM16 14V5H0v9a2 2 0 002 2h12a2 2 0 002-2zM8.5 8.5V10H10a.5.5 0 010 1H8.5v1.5a.5.5 0 01-1 0V11H6a.5.5 0 010-1h1.5V8.5a.5.5 0 011 0z' />
		</svg>
	);
}

export default SvgCalendarPlusFill;
