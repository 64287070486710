import * as React from 'react';

function SvgSkype(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M4.671 0c.88 0 1.733.247 2.468.702a7.423 7.423 0 016.02 2.118 7.372 7.372 0 012.167 5.215c0 .344-.024.687-.072 1.026a4.662 4.662 0 01.6 2.281 4.645 4.645 0 01-1.37 3.294A4.673 4.673 0 0111.18 16c-.84 0-1.658-.226-2.37-.644a7.423 7.423 0 01-6.114-2.107A7.374 7.374 0 01.529 8.035c0-.363.026-.724.08-1.081a4.644 4.644 0 01.76-5.59A4.68 4.68 0 014.67 0zm.447 7.01c.18.309.43.572.729.769a7.07 7.07 0 001.257.653c.492.205.873.38 1.145.523.229.112.437.264.615.448.135.142.21.331.21.528a.872.872 0 01-.335.723c-.291.196-.64.289-.99.264a2.618 2.618 0 01-1.048-.206 11.44 11.44 0 01-.532-.253 1.284 1.284 0 00-.587-.15.717.717 0 00-.501.176.63.63 0 00-.195.491.796.796 0 00.148.482 1.2 1.2 0 00.456.354 5.113 5.113 0 002.212.419 4.554 4.554 0 001.624-.265 2.296 2.296 0 001.08-.801c.267-.39.402-.855.386-1.327a2.09 2.09 0 00-.279-1.101 2.53 2.53 0 00-.772-.792A7.198 7.198 0 008.486 7.3a1.05 1.05 0 00-.145-.058 18.182 18.182 0 01-1.013-.447 1.827 1.827 0 01-.54-.387.727.727 0 01-.2-.508.805.805 0 01.385-.723 1.76 1.76 0 01.968-.247c.26-.003.52.03.772.096.274.079.542.177.802.293.105.049.22.075.336.076a.6.6 0 00.453-.19.69.69 0 00.18-.496.717.717 0 00-.17-.476 1.374 1.374 0 00-.556-.354 3.69 3.69 0 00-.708-.183 5.963 5.963 0 00-1.022-.078 4.53 4.53 0 00-1.536.258 2.71 2.71 0 00-1.174.784 1.91 1.91 0 00-.45 1.287c-.01.37.076.736.25 1.063z' />
		</svg>
	);
}

export default SvgSkype;
