import React, { useState, useEffect } from 'react'
import { marked as parseMarkdown } from 'marked'
import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from 'facit/components/bootstrap/OffCanvas';
import Button from 'facit/components/bootstrap/Button';

const PageHelper: React.FC = () => {
  const { i18n, t } = useTranslation('components', { keyPrefix: 'pageHelper' });
  const [htmlContent, setHtmlContent] = useState('')
  const [state, setState] = useState(false)
  const location = useLocation()
  const [showButton, setShowButton] = useState(false)

  const routeName = (location: string) => {
    const routes: { [key: string]: string } = {
      "/organizations\/([0-9]+)\/courses\/([0-9]+)#Dashboard": "courseDashboard",
      "/organizations\/([0-9]+)\/courses\/([0-9]+)#StudentsInRisk": "courseStudentsInRisk",
      "/organizations\/([0-9]+)\/courses\/([0-9]+)#Students": "courseStudents",
      "/organizations\/([0-9]+)\/courses\/([0-9]+)#Engagement": "courseEngagement",
      "/organizations\/([0-9]+)\/courses\/([0-9]+)#Activities": "courseActivities",
      "/organizations\/([0-9]+)\/courses#Overview": "organizationsCoursesOverview",
      "/organizations\/([0-9]+)\/courses#Courses": "organizationsCourses",
      "/organizations$": "organizationsIndex"
    };

    for (const pattern in routes) {
      if (new RegExp(pattern).test(location)) {
        return routes[pattern];
      }
    }

    return 'unknown';
  }

  const fileName = () => {
    const language = i18n.language;
    const route = `${location.pathname}${location.hash}`;

    return `https://lanse.com.br/help/${language}/${routeName(route)}.md`
  }

  const loadMarkdown = () => {
    fetch(fileName(), {
      cache: 'no-cache',
    }).then(response => response.text() ).then(markdown => {
      const html = parseMarkdown(markdown, {
        breaks: true,
        gfm: true
      })
      const sanitizedHtml = DOMPurify.sanitize(html)

      if (sanitizedHtml !== '') {
        setShowButton(true)
      } else {
        setShowButton(false)
      }

      setHtmlContent(sanitizedHtml)
    }).catch(() => { setShowButton(false) })
  }

  useEffect(() => { loadMarkdown() }, [location.pathname, location.hash, i18n.language]);

  return showButton ? <>
    <Button
      size="lg"
      icon="Help"
      hoverShadow="sm"
      color='primary'
      isLink={true}
      onClick={() => { setState(true) }}
    />

    <OffCanvas tag="div" isBodyScroll={true} isOpen={state} setOpen={setState}>
      <OffCanvasHeader id="help">
        <OffCanvasTitle>{ t('sidebar.title') }</OffCanvasTitle>
      </OffCanvasHeader>

      <OffCanvasBody>
        <div
          style={{ overflow: 'hidden' }}
          className="help-content"
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </OffCanvasBody>
    </OffCanvas>
  </> : <></>
}

export default PageHelper
