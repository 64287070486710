import * as React from 'react';

function SvgSave2(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M2 1a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V2a1 1 0 00-1-1H9.5a1 1 0 00-1 1v4.5h2a.5.5 0 01.354.854l-2.5 2.5a.5.5 0 01-.708 0l-2.5-2.5A.5.5 0 015.5 6.5h2V2a2 2 0 012-2H14a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h2.5a.5.5 0 010 1H2z' />
		</svg>
	);
}

export default SvgSave2;
