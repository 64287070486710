import React, { useContext } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Content from '../Content/Content'
import WrapperOverlay from './WrapperOverlay'
import ThemeContext from '../../contexts/themeContext'
import Notifications from 'components/Notifications'

interface WrapperContainerProps {
  children: JSX.Element[]
  className: string
};

export const WrapperContainer = ({ children, className, ...props }: WrapperContainerProps) => {
  const { rightPanel }: any = useContext(ThemeContext)
  return (
		<div
			className={classNames(
			  'wrapper',
			  { 'wrapper-right-panel-active': rightPanel },
			  className
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</div>
  )
}
WrapperContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}
WrapperContainer.defaultProps = {
  className: null
}

const Wrapper = () => {
  return (
		<>
			<WrapperContainer>
				<Notifications />
				<Content />
				<></>
			</WrapperContainer>
			<WrapperOverlay />
		</>
  )
}

export default Wrapper
