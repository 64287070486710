import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import React, { useRef } from "react";
import { ThemeContextProvider } from "./facit/contexts/themeContext";
import { getOS } from "./facit/helpers/helpers";
import Aside from "./facit/layout/Aside/Aside";
import AdminAside from "./facit/layout/Aside/AdminAside";
import CourseAside from "./facit/layout/Aside/CourseAside";
import OrganizationAside from "./facit/layout/Aside/OrganizationAside";
import Wrapper from "./facit/layout/Wrapper/Wrapper";
import { NotificationProvider } from './contexts/notifications'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const RoutesDelegator: React.FC = (): JSX.Element => {
  return <AuthenticatedArea />;
};

function App(): JSX.Element {
  return (
    <ThemeContextProvider>
      <RoutesDelegator />
    </ThemeContextProvider>
  );
}

const AuthenticatedArea: React.FC = (): JSX.Element => {
  getOS();
  const ref = useRef(null);

  return (
    <NotificationProvider>
      <Router>
        <div ref={ref} className='app'>
          <Routes>
            <Route path="" element={<Navigate to="/organizations" />} />
            <Route path='login' element={null} />
            <Route path='sign-in' element={null} />
            <Route path='password/*' element={null} />
            <Route path='feedback' element={<OrganizationAside />} />
            <Route path='organizations' element={<OrganizationAside />} />
            <Route path='admin/*' element={<AdminAside />} />
            <Route path='organizations/:orgId/courses' element={<CourseAside />} />
            <Route path='organizations/:orgId/courses/:id/*' element={<Aside />} />
            <Route path='*' element={<OrganizationAside />} />
          </Routes>
          <Wrapper/>
        </div>
      </Router>
    </NotificationProvider>
  );
};

export default App;
