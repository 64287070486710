import { track } from '@amplitude/analytics-browser'

export default (eventType: string, eventProperties: object) => {
  track({
    event_type: eventType,
    event_properties: eventProperties,
    user_id: me().email,
    user_properties: userProperties()
  })
}

const userProperties = () => {
  return {
    email: me().email,
    id: me().id,
    firstName: me().firstName,
    lastName: me().lastName,
  }
}

const me = () => {
  return JSON.parse(localStorage.getItem('me') || '{}')
}
