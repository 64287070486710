import * as React from 'react';

function SvgGift(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M3 2.5a2.5 2.5 0 015 0 2.5 2.5 0 015 0v.006c0 .07 0 .27-.038.494H15a1 1 0 011 1v2a1 1 0 01-1 1v7.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 011 14.5V7a1 1 0 01-1-1V4a1 1 0 011-1h2.038A2.968 2.968 0 013 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 10-3 0c0 .085.002.274.045.43a.522.522 0 00.023.07zM9 3h2.932a.56.56 0 00.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 00-3 0V3zM1 4v2h6V4H1zm8 0v2h6V4H9zm5 3H9v8h4.5a.5.5 0 00.5-.5V7zm-7 8V7H2v7.5a.5.5 0 00.5.5H7z' />
		</svg>
	);
}

export default SvgGift;
