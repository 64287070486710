import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import _ from 'lodash'

const compileStrings = ctx => {
  const compiled = { resources: {} };

  ctx.keys().forEach(file => {
    compiled.resources = _.merge(ctx(file), compiled.resources)
  });

  return compiled;
};

const translations = compileStrings(require.context('./locales', true, /\.json$/));

i18n.use(initReactI18next).init({
  resources: translations.resources,
  ns: [ 'translations' ],
  fallbackLng: 'pt-BR',
  debug: process.env.NODE_ENV === 'development',

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  returnObjects: true,
});

export default i18n
