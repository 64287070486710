import * as React from 'react';

function SvgBakeryDining(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M7.6 8.67l-2.01.8c-.22.09-.34.31-.31.54l2.4 5.98h1.23l-.62-6.9a.503.503 0 00-.69-.42zM3.07 16.1c-.27.53.29 1.09.82.83l1.68-.84-1.08-2.71-1.42 2.72zM13.36 6.99h-2.71a.5.5 0 00-.5.54l.77 8.45h2.17l.77-8.45a.505.505 0 00-.5-.54zM18.41 9.47l-2.01-.8a.501.501 0 00-.68.42l-.62 6.9h1.23l2.4-5.98c.02-.23-.1-.45-.32-.54zM19.52 13.39l-1.08 2.7 1.68.84c.52.26 1.09-.3.82-.83l-1.42-2.71z'
				opacity={0.3}
			/>
			<path d='M20.5 10.94c.13-.32.1-.23.15-.39.3-1.21-.34-2.47-1.5-2.93l-2.01-.8c-.46-.18-.95-.21-1.41-.12-.11-.33-.29-.63-.52-.89-.48-.52-1.15-.81-1.85-.81h-2.71c-.71 0-1.38.29-1.85.81-.24.26-.42.56-.53.88-.46-.09-.95-.06-1.41.12l-2.01.8c-1.16.46-1.8 1.72-1.5 2.93l.15.38C1.1 15.55 1 15.55 1 16.38c0 .91.46 1.74 1.24 2.22 1.42.88 2.49.14 4-.61h11.53c1.52.76 1.86 1.01 2.63 1.01 1 0 2.61-.77 2.61-2.61-.01-.85-.13-.88-2.51-5.45zM3.88 16.93c-.53.26-1.09-.3-.82-.83l1.41-2.72 1.08 2.71-1.67.84zm3.8-.94l-2.4-5.98c-.03-.23.09-.45.31-.54l2.01-.8c.31-.12.65.08.68.42l.62 6.9H7.68zm5.41 0h-2.17l-.77-8.45a.5.5 0 01.5-.54h2.71a.5.5 0 01.5.54l-.77 8.45zm3.23 0h-1.23l.62-6.9c.03-.33.37-.54.68-.42l2.01.8c.22.09.34.31.31.54l-2.39 5.98zm3.8.94l-1.68-.84 1.08-2.7 1.41 2.71c.28.53-.29 1.09-.81.83z' />
		</svg>
	);
}

export default SvgBakeryDining;
