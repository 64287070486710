export const getPasswordToken = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const t = params.get("password_token")

  if (t) {
    return t;
  }

  return "";
};

