import * as React from 'react';

function SvgEnvelopeOpenFill(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M8.941.435a2 2 0 00-1.882 0l-6 3.2A2 2 0 000 5.4v.313l6.709 3.933L8 8.928l1.291.717L16 5.715V5.4a2 2 0 00-1.059-1.765l-6-3.2zM16 6.873l-5.693 3.337L16 13.372v-6.5zm-.059 7.611L8 10.072.059 14.484A2 2 0 002 16h12a2 2 0 001.941-1.516zM0 13.373l5.693-3.163L0 6.873v6.5z' />
		</svg>
	);
}

export default SvgEnvelopeOpenFill;
