import * as React from 'react';

function SvgHdrPlus(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M12 4c-4.41 0-8 3.59-8 8 0 2.52 1.17 4.77 3 6.24V13h3.5c.8 0 1.5.7 1.5 1.5v1c0 .6-.4 1.1-.9 1.4L12 19h-1.5l-.9-2H8.5v2h-.37c1.15.64 2.47 1 3.87 1 4.41 0 8-3.59 8-8s-3.59-8-8-8zm0 8h-1.5V9.5h-2V12H7V6h1.5v2h2V6H12v6zm5.5 4H16v1.5h-1.5V16H13v-1.5h1.5V13H16v1.49h1.5V16zm0-5.5c0 .8-.7 1.5-1.5 1.5h-3V6h3c.8 0 1.5.7 1.5 1.5v3z'
				opacity={0.3}
			/>
			<path opacity={0.3} d='M14.5 7.5H16v3h-1.5zM8.5 14.5h2v1h-2z' />
			<path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-1.4 0-2.72-.36-3.87-1h.37v-2h1.1l.9 2H12l-.9-2.1c.5-.3.9-.8.9-1.4v-1c0-.8-.7-1.5-1.5-1.5H7v5.24C5.17 16.77 4 14.52 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8-3.59 8-8 8zm-3.5-4.5v-1h2v1h-2z' />
			<path d='M10.5 8h-2V6H7v6h1.5V9.5h2V12H12V6h-1.5zM16 6h-3v6h3c.8 0 1.5-.7 1.5-1.5v-3c0-.8-.7-1.5-1.5-1.5zm0 4.5h-1.5v-3H16v3zM16 13h-1.5v1.5H13V16h1.5v1.5H16V16h1.5v-1.51H16z' />
		</svg>
	);
}

export default SvgHdrPlus;
