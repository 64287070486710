import React, { useState } from 'react'
import Button from 'facit/components/bootstrap/Button';
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from 'facit/components/bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player'

const NotificationModal = (props: { isOpen: Boolean, title: string, message: string, urlImage: string, urlVideo: string, urlTarget: string, id: Number, readAt: Date, readNotification: Function }): React.ReactElement => {
  const { t } = useTranslation('notifications', {keyPrefix: 'notificationModal'});

  const { isOpen, title, message, urlImage, urlVideo, urlTarget, id, readAt, readNotification } = props

  const [state, setState] = useState(isOpen || false);

  const hasMedia = () => {
    return urlImage || urlVideo
  }

  return (
    <>
      <Button
        color="link"
        style={{padding: 0}}
        onClick={() => { setState(true); !readAt && readNotification(id) }}
      >
        {t('openModal')}
      </Button>
      <Modal isOpen={state} setIsOpen={setState} isCentered={true}>
        <ModalHeader setIsOpen={setState} style={{ backgroundColor:  hasMedia() && '#564aa9'}} />

        <ModalBody style={{padding: 0}}>
          {
            hasMedia() && <div style={{marginBottom: 20, padding: "10px 30px 40px 30px", backgroundColor: '#564aa9'}}>
              { urlImage && <img style={{width: '440px', height: '247px'}} src={urlImage} alt="" /> }
              { urlVideo && <ReactPlayer url={urlVideo} width="440px" height="247px" controls={true} autoplay={true} /> }
            </div>
          }

          <div className="content-container" style={{padding: '0 30px'}}>
            <ModalTitle>{title}</ModalTitle>

            <p style={{textAlign: 'justify', marginTop: 10}}>
              {message}
            </p>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button href={urlTarget} tag="a" target="_blank" color="primary">
            {t('accessLink')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default NotificationModal
