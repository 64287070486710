import * as React from 'react';

function SvgThermometerSnow(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M5 12.5a1.5 1.5 0 11-2-1.415V9.5a.5.5 0 011 0v1.585A1.5 1.5 0 015 12.5z' />
			<path d='M1 2.5a2.5 2.5 0 015 0v7.55a3.5 3.5 0 11-5 0V2.5zM3.5 1A1.5 1.5 0 002 2.5v7.987l-.167.15a2.5 2.5 0 103.333 0L5 10.486V2.5A1.5 1.5 0 003.5 1zm5 1a.5.5 0 01.5.5v1.293l.646-.647a.5.5 0 01.708.708L9 5.207v1.927l1.669-.963.495-1.85a.5.5 0 11.966.26l-.237.882 1.12-.646a.5.5 0 01.5.866l-1.12.646.884.237a.5.5 0 11-.26.966l-1.848-.495L9.5 8l1.669.963 1.849-.495a.5.5 0 11.258.966l-.883.237 1.12.646a.5.5 0 01-.5.866l-1.12-.646.237.883a.5.5 0 11-.966.258L10.67 9.83 9 8.866v1.927l1.354 1.353a.5.5 0 01-.708.708L9 12.207V13.5a.5.5 0 01-1 0v-11a.5.5 0 01.5-.5z' />
		</svg>
	);
}

export default SvgThermometerSnow;
