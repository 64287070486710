import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Icon from '../../../facit/components/icon/Icon'

const Brand = ({ asideStatus, setAsideStatus }) => {
  return (
		<div className='brand'>
			<div className='brand-logo'>
				<h1 className='brand-title'>
					<Link to={'/organizations'} aria-label='Logo' alt='Facit'>
						<span className='brand-description'>Menu</span>
					</Link>
				</h1>
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close' />
				<Icon icon='LastPage' className='brand-aside-toggle-open' />
			</button>
		</div>
  )
}
Brand.propTypes = {
  asideStatus: PropTypes.bool.isRequired,
  setAsideStatus: PropTypes.func.isRequired
}

export default Brand
