import * as React from 'react';

function SvgBallot(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M7 14h3v3H7zm0-7h3v3H7zM5 19h14V5H5v14zm8-11.5h5v2h-5v-2zm0 7h5v2h-5v-2zM6 6h5v5H6V6zm0 7h5v5H6v-5z'
				opacity={0.3}
			/>
			<path d='M13 7.5h5v2h-5zm0 7h5v2h-5zM19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM11 6H6v5h5V6zm-1 4H7V7h3v3zm1 3H6v5h5v-5zm-1 4H7v-3h3v3z' />
		</svg>
	);
}

export default SvgBallot;
