import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

interface PageProps {
  className?: string
  container: null | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'fluid' | boolean
  children: JSX.Element
}

const Page = forwardRef<HTMLDivElement, PageProps>(({children, className = null, container = null, ...props}, ref) => {
  return (
    <div
      ref={ref}
      className={classNames('page', className, {
        [`container${typeof container === 'string' ? `-${container}` : ''}`]: container
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}>
      {children}
    </div>
  )
})

export default Page
