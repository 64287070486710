import * as React from 'react';

function SvgParagliding(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M21 4.31C20.65 3.63 17.57 2 12 2S3.35 3.63 3 4.31v1.77C5.34 5.07 8.56 4.5 12 4.5s6.66.57 9 1.58V4.31z'
				opacity={0.3}
			/>
			<path d='M12 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.48.94C14.68 18.54 14 19 12 19s-2.68-.46-3.48-1.06C8.04 17.55 7 16.76 7 14H5c0 2.7.93 4.41 2.3 5.5.5.4 1.1.7 1.7.9V24h6v-3.6c.6-.2 1.2-.5 1.7-.9 1.37-1.09 2.3-2.8 2.3-5.5h-2c0 2.76-1.04 3.55-1.52 3.94zM23 4.25v3.49c0 .8-.88 1.26-1.56.83-.14-.09-.28-.18-.44-.26L19 13h-2l-1.5-6.28a27.852 27.852 0 00-7 0L7 13H5L3 8.31c-.16.08-.3.17-.44.26C1.88 9 1 8.55 1 7.74V4.25C1 1.9 5.92 0 12 0s11 1.9 11 4.25zM6.9 6.98c-.93.19-1.78.43-2.53.71l1.51 3.55L6.9 6.98zm12.73.71c-.75-.28-1.6-.52-2.53-.71l1.02 4.25 1.51-3.54zM21 4.31C20.65 3.63 17.57 2 12 2S3.35 3.63 3 4.31v1.77C5.34 5.07 8.56 4.5 12 4.5s6.66.57 9 1.58V4.31z' />
		</svg>
	);
}

export default SvgParagliding;
