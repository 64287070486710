import HttpProviderFactory from '../../providers/factories/HttpProviderFactory'
import HttpDashboardRepository, { NotificationRespositories } from '../NotificationRepositories'

export default class NotificationFactory {
  private static instance: NotificationRespositories

  public static getInstance (): NotificationRespositories{
    if (!NotificationFactory.instance) {
      const httpProvider = HttpProviderFactory.getInstance()
      NotificationFactory.instance = new HttpDashboardRepository(httpProvider)
    }

    return NotificationFactory.instance
  }
}
