import * as React from 'react';

function SvgCloudSlashFill(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path
				fillRule='evenodd'
				d='M3.112 5.112a3.125 3.125 0 00-.17.613C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13H11L3.112 5.112zm11.372 7.372L4.937 2.937A5.512 5.512 0 018 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773a3.2 3.2 0 01-1.516 2.711zm-.838 1.87l-12-12 .708-.708 12 12-.707.707z'
			/>
		</svg>
	);
}

export default SvgCloudSlashFill;
