import * as React from 'react';

function SvgFilterVintage(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M18.69 7.61c-.7 0-1.39.19-2 .54-.16.09-.32.21-.54.37l-1.13.9c.31.36.56.78.72 1.24l1.38-.52c.22-.08.41-.17.58-.27.84-.49 1.47-1.27 1.78-2.18-.26-.06-.52-.08-.79-.08zm-1.56 6.26l-1.38-.52c-.16.45-.4.85-.7 1.21l1.15.96c.15.12.31.24.49.34.61.35 1.3.54 1.99.54.27 0 .53-.03.8-.08-.31-.91-.94-1.69-1.78-2.18-.16-.1-.35-.19-.57-.27zM11 6c0 .19.02.41.05.63l.23 1.44c.24-.04.48-.07.72-.07s.48.03.71.07l.23-1.44c.04-.22.06-.44.06-.63 0-.98-.37-1.91-1-2.63-.63.72-1 1.65-1 2.63zm1.71 9.93c-.23.04-.47.07-.71.07-.25 0-.49-.03-.72-.07l-.22 1.42c-.04.24-.06.45-.06.65 0 .98.37 1.91 1 2.63.63-.72 1-1.66 1-2.63 0-.19-.02-.41-.05-.63l-.24-1.44zm-5.84-5.81l1.38.52c.16-.44.4-.85.7-1.2L7.8 8.49c-.17-.15-.34-.27-.49-.35-.62-.36-1.3-.54-2-.54-.27 0-.54.03-.81.08.3.91.93 1.68 1.79 2.18.17.09.36.18.58.26zm0 3.74c-.22.08-.41.17-.58.27-.84.49-1.47 1.27-1.78 2.18.26.05.52.08.79.08.7 0 1.39-.19 2-.54.16-.09.32-.21.54-.37l1.13-.89c-.31-.36-.56-.78-.72-1.24l-1.38.51z'
				opacity={0.3}
			/>
			<path d='M18.7 12.4a6.06 6.06 0 00-.86-.4c.29-.11.58-.24.86-.4a6.012 6.012 0 003-5.19c-.91-.52-1.95-.8-3.01-.8-1.02 0-2.05.26-2.99.8-.28.16-.54.35-.78.54.05-.31.08-.63.08-.95 0-2.22-1.21-4.15-3-5.19C10.21 1.85 9 3.78 9 6c0 .32.03.64.08.95-.24-.2-.5-.39-.78-.55-.94-.54-1.97-.8-2.99-.8-1.05 0-2.1.28-3.01.8a5.97 5.97 0 003 5.19c.28.16.57.29.86.4-.29.11-.58.24-.86.4a6.012 6.012 0 00-3 5.19c.91.52 1.95.8 3.01.8 1.02 0 2.05-.26 2.99-.8.28-.16.54-.35.78-.54-.05.32-.08.64-.08.96 0 2.22 1.21 4.15 3 5.19 1.79-1.04 3-2.97 3-5.19 0-.32-.03-.64-.08-.95.24.2.5.38.78.54.94.54 1.97.8 2.99.8 1.05 0 2.1-.28 3.01-.8a6.012 6.012 0 00-3-5.19zM4.51 7.68c.26-.06.53-.08.8-.08.69 0 1.38.18 1.99.54.15.09.32.2.49.35l1.15.96c-.3.36-.53.76-.7 1.2l-1.38-.52c-.21-.09-.4-.18-.56-.27-.87-.5-1.49-1.27-1.79-2.18zm3.33 7.79c-.21.17-.38.29-.54.37-.61.35-1.3.54-2 .54-.27 0-.53-.03-.79-.08.31-.91.94-1.69 1.78-2.18.17-.1.36-.18.58-.27l1.38-.52c.16.46.41.88.72 1.24l-1.13.9zM12 3.37c.63.72 1 1.66 1 2.63 0 .19-.02.41-.05.63l-.23 1.44C12.48 8.03 12.24 8 12 8s-.48.03-.71.07l-.23-1.44C11.02 6.41 11 6.19 11 6c0-.98.37-1.91 1-2.63zm0 17.26c-.63-.72-1-1.66-1-2.63 0-.2.02-.41.06-.65l.22-1.42c.23.04.47.07.72.07.24 0 .48-.03.71-.07l.23 1.44c.04.22.06.44.06.63 0 .98-.37 1.91-1 2.63zM12 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm4.16-5.48c.21-.17.38-.29.54-.37.61-.35 1.3-.54 2-.54.27 0 .53.03.79.08-.31.91-.94 1.69-1.78 2.18-.17.1-.36.18-.58.27l-1.38.52c-.17-.46-.41-.87-.72-1.24l1.13-.9zm2.53 7.87a3.916 3.916 0 01-2.48-.88l-1.15-.96c.3-.36.54-.76.7-1.21l1.38.52c.22.08.41.17.57.26.85.49 1.47 1.27 1.78 2.18-.27.07-.54.09-.8.09z' />
		</svg>
	);
}

export default SvgFilterVintage;
