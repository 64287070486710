import * as React from 'react';

function SvgBinoculars(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M3 2.5A1.5 1.5 0 014.5 1h1A1.5 1.5 0 017 2.5V5h2V2.5A1.5 1.5 0 0110.5 1h1A1.5 1.5 0 0113 2.5v2.382a.5.5 0 00.276.447l.895.447A1.5 1.5 0 0115 7.118V14.5a1.5 1.5 0 01-1.5 1.5h-3A1.5 1.5 0 019 14.5v-3a.5.5 0 01.146-.354l.854-.853V9.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v.793l.854.853A.5.5 0 017 11.5v3A1.5 1.5 0 015.5 16h-3A1.5 1.5 0 011 14.5V7.118a1.5 1.5 0 01.83-1.342l.894-.447A.5.5 0 003 4.882V2.5zM4.5 2a.5.5 0 00-.5.5V3h2v-.5a.5.5 0 00-.5-.5h-1zM6 4H4v.882a1.5 1.5 0 01-.83 1.342l-.894.447A.5.5 0 002 7.118V13h4v-1.293l-.854-.853A.5.5 0 015 10.5v-1A1.5 1.5 0 016.5 8h3A1.5 1.5 0 0111 9.5v1a.5.5 0 01-.146.354l-.854.853V13h4V7.118a.5.5 0 00-.276-.447l-.895-.447A1.5 1.5 0 0112 4.882V4h-2v1.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V4zm4-1h2v-.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V3zm4 11h-4v.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V14zm-8 0H2v.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V14z' />
		</svg>
	);
}

export default SvgBinoculars;
