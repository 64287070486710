import React, {useLayoutEffect, forwardRef} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

interface PageWrapperProps {
  title?: string
  description?: string
  className?: string
  children: JSX.Element
}

const PageWrapper = forwardRef<HTMLInputElement, PageWrapperProps>(({title = null, description= null, className= null, children}, ref) => {
  useLayoutEffect(() => {
    let elementsByTagNameElement = document.getElementsByTagName('TITLE')[0];

    elementsByTagNameElement.textContent = `${title ? `${title} | ` : ''}${ process.env.REACT_APP_SITE_NAME}`

    document
      .querySelector('meta[name="description"]')
      ?.setAttribute('content', description || process.env.REACT_APP_META_DESC || "LANSE Dashboard")
  })

  return (
    <div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
      {children}
    </div>
  )
})

export default PageWrapper
