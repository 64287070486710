import React, { lazy } from "react";
import { pages, dashboard } from "../menu";
import { coursesMenu } from "../coursesMenu";

const BASE = {
  PAGE_404: lazy(async () => import("../../facit/pages/base/Page404")),
};

const DASHBOARD = {
  INDEX: lazy(async () => import("../../pages/dashboard/index/Dashboard")),
};

const COURSE = {
  INDEX: lazy(async () => import("../../pages/course/index/OffersPage")),
};

const FEEDBACK = {
  INDEX: lazy(async () => import("../../pages/feedback/index/Index")),
};

const LOGIN = {
  INDEX: lazy(async () => import("../../pages/login/Index")),
};

const SIGNIN = {
  INDEX: lazy(async () => import("../../pages/sign-in/Index")),
};

const PASSWORD = {
  RECOVERY: lazy(async () => import("../../pages/password/recovery/Index")),
  NEW: lazy(async () => import("../../pages/password/new/Index")),
};

const ORGANIZATION = {
  INDEX: lazy(async () => import("../../pages/organizations/index/Index"))
};

const ADMIN = {
  ORGANIZATION_INDEX: lazy(async () => import("../../pages/admin/organization/Index")),
  ORGANIZATION_SHOW: lazy(async () => import("../../pages/admin/organization/Show")),
  COURSE_SHOW: lazy(async () => import("../../pages/admin/course/Show")),
  USER_INDEX: lazy(async () => import("../../pages/admin/users/Index")),
};

const presentation = [
  {
    path: pages.page404.path,
    element: <BASE.PAGE_404 />,
    exact: true,
  },
  {
    path: dashboard.index.path,
    element: <DASHBOARD.INDEX />,
    exact: true,
  },
  {
    path: 'login',
    element: <LOGIN.INDEX />,
    exact: true,
  },
  {
    path: 'sign-in',
    element: <SIGNIN.INDEX />,
    exact: true,
  },
  {
    path: 'password/recovery',
    element: <PASSWORD.RECOVERY />,
    exact: true,
  },
  {
    path: 'password/new',
    element: <PASSWORD.NEW />,
    exact: true,
  },
  {
    path: coursesMenu.index.path,
    element: <COURSE.INDEX />,
    exact: true,
  },
  {
    path: 'feedback',
    element: <FEEDBACK.INDEX />,
    exact: true,
  },
  {
    path: 'organizations',
    element: <ORGANIZATION.INDEX />,
    exact: true
  },
  {
    path: 'admin/organizations',
    element: <ADMIN.ORGANIZATION_INDEX />,
    exact: true
  },
  {
    path: 'admin/organizations/:id',
    element: <ADMIN.ORGANIZATION_SHOW />,
    exact: true
  },
  {
    path: 'admin/organizations/:orgId/courses/:id',
    element: <ADMIN.COURSE_SHOW />,
    exact: true
  },
  {
    path: 'admin/users',
    element: <ADMIN.USER_INDEX />,
    exact: true
  }
];

const contents = [...presentation];

export default contents;
