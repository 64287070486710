import * as React from 'react';

function SvgHandyman(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M8.66 14.64l-4.25 4.24.71.71 4.24-4.25-.7-.7zM14.596 15.345l.707-.707 4.242 4.243-.707.707z'
				opacity={0.3}
			/>
			<path d='M21.67 18.17l-5.3-5.3h-.99l-2.54 2.54v.99l5.3 5.3c.39.39 1.02.39 1.41 0l2.12-2.12a.996.996 0 000-1.41zm-2.83 1.42l-4.24-4.24.71-.71 4.24 4.24-.71.71z' />
			<path d='M17.34 10.19l1.41-1.41 2.12 2.12a3 3 0 000-4.24l-3.54-3.54-1.41 1.41V1.71l-.7-.71-3.54 3.54.71.71h2.83l-1.41 1.41 1.06 1.06-2.89 2.89-4.13-4.13V5.06L4.83 2.04 2 4.87 5.03 7.9h1.41l4.13 4.13-.85.85H7.6l-5.3 5.3a.996.996 0 000 1.41l2.12 2.12c.39.39 1.02.39 1.41 0l5.3-5.3v-2.12l5.15-5.15 1.06 1.05zm-7.98 5.15l-4.24 4.24-.71-.71 4.24-4.24.71.71z' />
		</svg>
	);
}

export default SvgHandyman;
