import * as React from 'react';

function SvgLayerForward(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M8.354.146a.5.5 0 00-.708 0l-3 3a.5.5 0 000 .708l1 1a.5.5 0 00.708 0L7 4.207V12H1a1 1 0 00-1 1v2a1 1 0 001 1h14a1 1 0 001-1v-2a1 1 0 00-1-1H9V4.207l.646.647a.5.5 0 00.708 0l1-1a.5.5 0 000-.708l-3-3z' />
			<path d='M1 7a1 1 0 00-1 1v2a1 1 0 001 1h4.5a.5.5 0 000-1H1V8h4.5a.5.5 0 000-1H1zm9.5 0a.5.5 0 000 1H15v2h-4.5a.5.5 0 000 1H15a1 1 0 001-1V8a1 1 0 00-1-1h-4.5z' />
		</svg>
	);
}

export default SvgLayerForward;
