import React from 'react'
import Alert from 'facit/components/bootstrap/Alert';
import NotificationModal from './NotificationModal';

const PopupAlert = (props: { title: string, message: string, urlImage: string, urlVideo: string, id: Number, urlTarget: string, readAt: Date, readNotification: Function }): React.ReactElement => {
  const {
    title,
    message,
    urlImage,
    urlVideo,
    urlTarget,
    id,
    readAt,
    readNotification
  } = props

  return (
    <Alert tag="h2" isLight={true} color={!!readAt ? "dark" : "primary"}>
      <>
        <h5>{title}</h5>

        <NotificationModal
          isOpen={false}
          title={title}
          message={message}
          urlImage={urlImage}
          urlVideo={urlVideo}
          urlTarget={urlTarget}
          readAt={readAt}
          id={id}
          readNotification={readNotification}
        />
      </>
  </Alert>
  )
}

export default PopupAlert
