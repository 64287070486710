import * as React from 'react';

function SvgSignpostSplitFill(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M7 16h2V6h5a1 1 0 00.8-.4l.975-1.3a.5.5 0 000-.6L14.8 2.4A1 1 0 0014 2H9v-.586a1 1 0 00-2 0V7H2a1 1 0 00-.8.4L.225 8.7a.5.5 0 000 .6l.975 1.3a1 1 0 00.8.4h5v5z' />
		</svg>
	);
}

export default SvgSignpostSplitFill;
