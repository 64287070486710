import React, { useContext } from 'react'
import { useWindowSize } from 'react-use'
import ThemeContext from '../../contexts/themeContext'

const WrapperOverlay = () => {
  const { width } = useWindowSize()
  const { asideStatus, setAsideStatus, setLeftMenuStatus, setRightMenuStatus }: any =
    useContext(ThemeContext)

  const reactAppMobileBreakpointSize = process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE
    ? process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE
    : 786
  if (asideStatus && width < reactAppMobileBreakpointSize) {
    return (
      <div
        role='presentation'
        onClick={() => {
          setAsideStatus(false)
          setLeftMenuStatus(false)
          setRightMenuStatus(false)
        }}
        className='wrapper-overlay'
      />
    )
  }
  return null
}

export default WrapperOverlay
