export const adminMenu = {
  index: {
    id: "Organizations",
    text: "Organizações",
    icon: "Home",
    path: "admin/organizations",
    submenu: null
  },

  usersIndex: {
    id: "Users",
    text: "Usuários",
    icon: "People",
    path: "admin/users",
    submenu: null
  }
};
