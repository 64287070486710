export const organizationsRoles = () => {
  return me().organizationsRoles || []
}

export const isAdmin = () => {
  return organizationsRoles().some((orgRole: { roleName: string }) => orgRole.roleName === 'LANSE')
}

const me = () => {
  if (!localStorage.me) return {}

  return JSON.parse(localStorage.me)
}
