export const dashboard = {
  index: {
    id: "General",
    text: "Visão geral",
    icon: "Home",
    path: "organizations/:orgId/courses/:id"
  }
};

export const pages = {
  page404: {
    id: "Page404",
    text: "404 Page",
    path: "base-pages/404",
    icon: "ReportGmailerrorred",
  },
};
