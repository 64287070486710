import React, { useEffect, useState, useMemo } from 'react'
import Button from 'facit/components/bootstrap/Button';
import Header from 'facit/layout/Header/Header';
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from 'facit/components/bootstrap/OffCanvas';
import Alert from './notifications/Alert'
import PopupAlert from './notifications/PopupAlert';
import NotificationFactory from "infra/repositories/factories/NotificationRepositoryFactory";
import NotificationModal from './notifications/NotificationModal';
import { useTranslation } from 'react-i18next';
import { getToken } from "utils/getToken";
import { useNotifications } from '../contexts/notifications';

import { ReactComponent as Logo } from '../assets/images/lanse_full_logo.svg'
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from 'facit/components/bootstrap/Dropdown';

import PageHelper from './PageHelper';
import track from '../utils/amplitude/track';

export interface NotificationInterface {
  title: string,
  message: string,
  isPopup: Boolean,
  expiresIn: Date,
  readAt: Date,
  urlImage: string,
  urlTarget: string,
  urlVideo: string,
  id: Number,
  createdAt: string
}

const Notifications = (): React.ReactElement => {
  const { i18n, t } = useTranslation('notifications');
  const { navbarSubheader, navbarSubheaderDescription } = useNotifications();

  const [state, setState] = useState(false);

  const repository = useMemo(() => NotificationFactory.getInstance(), []);
  const [notifications, setNotifications] = useState<NotificationInterface[]>([]);
  const [language, setLanguage] = useState(localStorage.getItem('location') || 'pt-BR')

  const unreadNotificationsCount = () => {
    return notifications.filter(notification => !notification.readAt).length
  }

  const readNotification = (id: Number) => {
    repository.readNotification(id).then(() => {
      loadNotifications()
    })
  }

  const popUpToOpen = (): any => {
    const notification = notifications.find(notification =>
      notification.isPopup && !notification.readAt
    )

    if (!!notification) {
      repository.readNotification(notification.id)
    }

    return notification
  }

  const loadNotifications = () => {
    repository.getNotifications().then(async (body: any) => {
      if (!body || body.status == 204) return setNotifications([]);

      setNotifications(body.data.data);
    });
  }

  useEffect(() => {
    if (!localStorage.getItem('location')) {
      localStorage.setItem('location', 'pt-BR')
    }

    getToken() && loadNotifications()
  }, [])

  useEffect(() => {
    localStorage.setItem('location', language)
    i18n.changeLanguage(language)
  }, [language])

  if (!getToken()) return <></>

  return (
    <>
      <Header>
        <div className="header-left col-sm d-flex align-items-center">
          <Logo style={{ width: '170px' }} />

          {
            navbarSubheader && (
              <div className="d-flex flex-column">
                <h3 className="fs-4 fw-bold mb-0 text-body">{navbarSubheader}</h3>
                {
                  navbarSubheaderDescription && <h4 className="fs-6 mb-0">{navbarSubheaderDescription}</h4>
                }
              </div>
            )
          }
        </div>

        <div className="header-right col-sm">
          <div className='d-flex flex-row-reverse'>
            <div className="d-flex col-auto">
              <div className="language-dropdown">
                <Dropdown direction="down">
                  <DropdownToggle hasIcon={false}>
                    <Button
                      size="lg"
                      icon={language == 'pt-BR' ? "CustomBrazil" : 'CustomUsa'}
                      hoverShadow="sm"
                      color='light'
                      isLight={true}
                    />
                  </DropdownToggle>

                  <DropdownMenu isAlignmentEnd={true}>
                    <DropdownItem onClick={() => {
                      setLanguage(language == 'pt-BR' ? 'en-US' : 'pt-BR')

                      track('Idioma - Alteração', { language: language })
                    }}>
                      <Button
                        icon={language != 'pt-BR' ? "CustomBrazil" : 'CustomUsa'}
                        hoverShadow="sm"
                        color='light'
                        isLight={true}
                      >
                        {language == 'pt-BR' ? 'English' : 'Português'}
                      </Button>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>

              <Button
                size="lg"
                icon="Notifications"
                hoverShadow="sm"
                color='light'
                isLight={true}
                onClick={() => {
                  track('Notificações - Abertura', {})

                  setState(true)
                }}
                className="position-relative"
              >
                {
                  unreadNotificationsCount() > 0 && <div
                    style={{ width: 15, height: 15, borderRadius: 10, margin: 0 }}
                    className='d-flex justify-content-center position-absolute start-50 top-0 bg-danger animate__animated animate__heartBeat animate__infinite animate__slower'
                  >
                    <span style={{ fontSize: 10 }} className="text-light fw-bold">
                      {unreadNotificationsCount()}
                    </span>
                  </div>
                }
              </Button>

              <PageHelper />
            </div>
          </div>
        </div>
      </Header>

      <OffCanvas tag="div" isBodyScroll={true} isOpen={state} setOpen={setState}>
        <OffCanvasHeader id="notification">
          <OffCanvasTitle>{t('sidebar.title')}</OffCanvasTitle>
        </OffCanvasHeader>

        <OffCanvasBody>
          {
            notifications.map(notification => {
              if (notification.isPopup) {
                return <PopupAlert
                  id={notification.id}
                  readAt={notification.readAt}
                  title={notification.title}
                  message={notification.message}
                  urlImage={notification.urlImage}
                  urlVideo={notification.urlVideo}
                  urlTarget={notification.urlTarget}
                  readNotification={readNotification}
                />
              }

              return <Alert
                id={notification.id}
                readAt={notification.readAt}
                title={notification.title}
                message={notification.message}
                readNotification={readNotification}
                createdAt={notification.createdAt}
              />
            })
          }
        </OffCanvasBody>
      </OffCanvas>

      {
        popUpToOpen() && <NotificationModal
          isOpen={true}
          id={popUpToOpen().id}
          readAt={popUpToOpen().readAt}
          title={popUpToOpen().title}
          message={popUpToOpen().message}
          urlImage={popUpToOpen().urlImage}
          urlVideo={popUpToOpen().urlVideo}
          urlTarget={popUpToOpen().urlTarget}
          readNotification={readNotification}
        />
      }
    </>
  )
}

export default Notifications
