import * as React from 'react';

function SvgHddStack(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M14 10a1 1 0 011 1v1a1 1 0 01-1 1H2a1 1 0 01-1-1v-1a1 1 0 011-1h12zM2 9a2 2 0 00-2 2v1a2 2 0 002 2h12a2 2 0 002-2v-1a2 2 0 00-2-2H2z' />
			<path d='M5 11.5a.5.5 0 11-1 0 .5.5 0 011 0zm-2 0a.5.5 0 11-1 0 .5.5 0 011 0zM14 3a1 1 0 011 1v1a1 1 0 01-1 1H2a1 1 0 01-1-1V4a1 1 0 011-1h12zM2 2a2 2 0 00-2 2v1a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H2z' />
			<path d='M5 4.5a.5.5 0 11-1 0 .5.5 0 011 0zm-2 0a.5.5 0 11-1 0 .5.5 0 011 0z' />
		</svg>
	);
}

export default SvgHddStack;
