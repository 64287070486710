import React from 'react';
import PropTypes from 'prop-types';

interface Props {
	children?: JSX.Element,
	tag: string,
}

const TagWrapper = React.forwardRef<HTMLInputElement, Props>(({ tag: Tag = 'div', children, ...props }: Props, ref) => {
	return (
		// @ts-ignore
		<Tag ref={ref} {...props}>
			{children}
		</Tag>
	);
});

export default TagWrapper;
