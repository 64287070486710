import { AxiosResponse } from 'axios'
import { IHTTPProvider } from '../providers/AxiosHttpProvider'

export interface NotificationRespositories {
  getNotifications: () => Promise<AxiosResponse | any>
  readNotification: (notificationId: Number) => Promise<AxiosResponse | any>
}

export default class HttpDashboardRepository implements NotificationRespositories {
  constructor(private readonly httpProvider: IHTTPProvider) { }

  public async getNotifications(): Promise<AxiosResponse | any> {
    const response = await this.httpProvider.get('v2/notifications')

    return response
  }

  public async readNotification(notificationId: Number): Promise<AxiosResponse | any> {
    const response = await this.httpProvider.put(`v2/notifications/${notificationId}`)

    return response
  }
}
