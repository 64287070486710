import React, { useState, useContext } from 'react';

interface Props {
  children: any;
}

interface NotificationContextData {
  navbarSubheader: string;
  setNavbarSubheader: any;
  navbarSubheaderDescription: string;
  setNavbarSubheaderDescription: any;
}

const initial = {} as unknown as NotificationContextData;

export const NotificationContext = React.createContext<NotificationContextData>(initial)

export const NotificationProvider = ({ children }: Props): JSX.Element => {
  const [navbarSubheader, setNavbarSubheader] = useState('')
  const [navbarSubheaderDescription, setNavbarSubheaderDescription] = useState('')

  return (
    <NotificationContext.Provider value={{
      navbarSubheader,
      setNavbarSubheader,
      navbarSubheaderDescription,
      setNavbarSubheaderDescription
    }}>
      {children}
    </NotificationContext.Provider>
  )
}

function useNotifications(): NotificationContextData {
  const context = useContext(NotificationContext);

  if (!context) throw new Error("Error");

  return context;
}

export { useNotifications };
