import App from './App'
import { createRoot } from 'react-dom/client'
import './styles.scss'
import './i18n'
import * as amplitude from '@amplitude/analytics-browser'

const container = document.getElementById('root')

const appKey = process.env.REACT_APP_AMPLITUDE_KEY || ''

// @ts-ignore
amplitude.init(appKey, { autocapture: false });

// eslint-disable-next-line
const root = createRoot(container!)

root.render(
  <App />
)
